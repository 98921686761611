import store from "@/store/store";
import services from "@/services/services.js";

export default {
    //todo: this is used as template, change content
    data() {
        return {

        }
    },
    computed: {
        isApplicationInitiated() {
            return store.state.conferenceId != ""
        },
        config() {
            return store.state.settings;
        }
    },
    methods: {
        InitApplication() {
            console.log("init cmGeoMap");
            let myWindowObject = window.cmGeoMap;
            if (myWindowObject) {
                store.setConferenceIdAction(myWindowObject.conferenceId);
                if (!myWindowObject.configCode) {
                    myWindowObject.configCode = "normal";
                }
                store.setConfigCodeAction(myWindowObject.configCode);
                store.setPersonIdAction(myWindowObject.personId);

                services.init();
                // services.getSettings().then((resp) => {
                //   var mySettings = resp.data.data;
                //   store.setDebugAction(mySettings.isDebug);
                //   store.setSettingsAction(mySettings);

                //   services
                //     .login(myWindowObject.accessToken, myWindowObject.encryptedUser)
                //     .then((resp) => {
                //       //todo: catch errors
                //       services.setToken(resp.data.data);
                //       this.$router.push({
                //         name: "SpeakerSearch",
                //       });
                //     });
                // });
            } else {
                alert("window.cmSpeakerDirectory not found");
            }
        }
    }
}