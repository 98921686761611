import axios from 'axios';
import store from "@/store/store.js";

let _svc;
//let _token = "";

export default {
    init: () => {
        _svc = axios.create({
            baseURL: process.env.VUE_APP_API_URL
        });
    },

    // setToken: (token) => {
    //     _token = token;
    // },

    // getSettings: () => {
    //     return _svc.get(`clientsettings/${store.state.conferenceId}/${store.state.configCode}`);
    // },

    // login: (oAuthAccessToken, encryptedUser) => {
    //     return _svc.post(`login`, {
    //         personId: store.state.personId,
    //         accessToken: oAuthAccessToken,
    //         encryptedUser: encryptedUser
    //     });
    // },


    getCountriesAndCount: () => {        
        return _svc.get(`geomap/Conference/${store.state.conferenceId}/Config/${store.state.configCode}/Participants/Countries`);
    },

    getTotalRegisteredDelegates: () => {
        return _svc.get(`geomap/Conference/${store.state.conferenceId}/Config/${store.state.configCode}/RegisteredDelegates`);
    },

    getTotalUniqueCountries: () => {
        return _svc.get(`geomap/Conference/${store.state.conferenceId}/Config/${store.state.configCode}/Participants/UniqueCountries`);
    },

    getTotalPersonsPerCountry: () => {
        return _svc.get(`geomap/Registrations/GetTotalRegistrationsPerCountry`);
    },

    getTotalPersonsUniqueCountries: () => {
        return _svc.get(`geomap/Registrations/GetTotalUniqueCountries`);
    },
    getTotalPersons: () => {
        return _svc.get(`geomap/Registrations/GetTotalPersons`);
    }


}