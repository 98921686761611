export default {
    state: {
        debug: false,
        conferenceId: "",
        configCode: "",
        personId:"",
        settings:{}
    },
    setDebugAction(newValue) {
        if (this.state.debug) console.log('STORE - setDebugAction triggered with', newValue)
        this.state.debug = newValue;
    },
    setConferenceIdAction(newValue) {
        if (this.state.debug) console.log('STORE - setConferenceIdAction triggered with', newValue)
        this.state.conferenceId = newValue;
    },
    setConfigCodeAction(newValue) {
        if (this.state.debug) console.log('STORE - setConfigCodeAction triggered with', newValue)
        this.state.configCode = newValue;
    },
    setPersonIdAction(newValue) {
        if (this.state.debug) console.log('STORE - setPersonIdAction triggered with', newValue)
        this.state.personId = newValue;
    },
    setSettingsAction(newValue) {
        if (this.state.debug) console.log('STORE - setSettingsAction triggered with', newValue)
        this.state.settings = newValue;
    }
}
