<template>
  <div class="home">
    <router-view />
  </div>
</template>

<script>
//import store from "@/store/store.js";
//import services from "@/services/services.js";
import generalMixin from "@/mixins/general.js";

export default {
  name: "Home",
  components: {},
  mixins: [generalMixin],
  methods: {
  },
  mounted() {
    console.log("home really mounted");
    this.InitApplication();
  },
};
</script>
