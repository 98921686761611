<template>
  <div>
    <h1>HighlightCountries</h1>
    <div class="mapContainer">
      <div id="mapid"></div>
    </div>
  </div>
</template>

<script>
import generalMixin from "@/mixins/general.js";
import geoJsonCountryList from "@/data/countrylist";
import jsonTotalsList from "@/data/totalsList";
import L from "leaflet";
import "leaflet-providers";
import services from "@/services/services.js";

export default {
  name: "HighlightCountries",
  mixins: [generalMixin],
  methods: {
    getTotalRegistrations: function (totals, mymap) {
      services.getTotalRegisteredDelegates()
        .then((resp) => {          
          services.getTotalUniqueCountries()
          .then((resp1) => {
            jsonTotalsList.totalsList = {
                totalRegistrations : resp.data.data.toString(),
                totalUniqueCountries : resp1.data.data.toString() 
            };        
            totals.addTo(mymap);
          });          
        });
    },
    getTotalPersonsAndCountries: function (totals, mymap) {
      services.getTotalPersons()
        .then((resp) => {          
          services.getTotalPersonsUniqueCountries()
          .then((resp1) => {
            jsonTotalsList.totalsList = {
                totalRegistrations : resp.data.data.toString(),
                totalUniqueCountries : resp1.data.data.toString() 
            };        
            totals.addTo(mymap);
          });          
        });
    },
    loadCountries: function () {
      // debugger;
      services
        .getCountriesAndCount()
        .then((resp) => {
          geoJsonCountryList.countryList.features.forEach((geoCountry) => {
            
            geoCountry.properties.isHighLight = false;
            geoCountry.properties.registrationCount = 0;

            resp.data.data.forEach((respCountry) => {
                if (respCountry.countryCode == geoCountry.properties.id2)
                  geoCountry.properties.registrationCount = respCountry.count; 
            });
            if (
              resp.data.data.find(
                (c) => c.countryCode === geoCountry.properties.id2
              )
            ) {
              geoCountry.properties.isHighLight = true;
            }
          });
          this.initMap();
        })
        .catch((error) => console.log(error));
    },

    loadPersonCountPerCountry: function () {
      services.getTotalPersonsPerCountry()
      .then((resp) => {
        geoJsonCountryList.countryList.features.forEach((geoCountry) => {
          geoCountry.properties.count = 0;

          resp.data.data.forEach((respCountry) => {
            if( respCountry.countryCode == geoCountry.properties.id2)
              geoCountry.properties.count = respCountry.count;
          });
        });
        this.initMap();
      })
      .catch((error) => console.log(error));
    },

    initMap: function () {
      // debugger;
    //MAP TILE LAYER
      // let layer = L.tileLayer.provider("CartoDB.PositronNoLabels", {
      //   noWrap: true,
      // });
      let mymap = new L.Map("mapid", {
        center: new L.LatLng(44.729038, -0.5),
        zoom: 1.5,
        zoomControl: false,
        scrollWheelZoom: false,
        doubleClickZoom: false,
        dragging: true
      });

      // mymap.addLayer(layer);

      //geoJson.addTo(mymap);
      let geoJson = L.geoJson(geoJsonCountryList.countryList).addTo(mymap);

      // sets colors based on value
      function getColor(isHighLight) {
        return isHighLight === true ? "#ff7652" : 
          '#ddd';
      }

      // sets colors based on total registrations
      function GetColorTotalRegCountry(count) {
        return count > 750 ? 'rgba(255,118,82,1)' :
           count > 500  ? 'rgba(255,118,82,0.9)' :
           count > 200  ? 'rgba(255,118,82,0.8)' :
           count > 100  ? 'rgba(255,118,82,0.7)' :
           count > 0   ? 'rgba(255,118,82,0.6)' :
                          '#F2F2F2';
      }


      //Color countries
      function style(feature) {
        return {
          fillColor: GetColorTotalRegCountry(feature.properties.count),
          fillOpacity: 1,
          weight: 1.75,
          opacity: 0.8,
          color: "#646463",
          dashArray: "0",
        };
      }

      geoJson = L.geoJson(geoJsonCountryList.countryList, {
        style: style,
      }).addTo(mymap);

      //INTERACTION (shows name of each country when hovered over)

      //When hovered over country, changes border weigth
      function highlightFeature(e) {
        let layer = e.target;

        layer.setStyle({
          weight: 2.5,
          color: "#646463",
          fillColor: "#e74a00",
          dashArray: "",
          // fillOpacity: 0.5,
        });

        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
          layer.bringToFront();
        }
        info.update(layer.feature.properties);
      }

      // Resets style when mouse leaves country
      function resetHighlight(e) {
        geoJson.resetStyle(e.target);
        info.update();
      }

      // Adds highlight and resetHighlight function to each Feature (country)
      function onEachFeature(feature, layer) {
        layer.on({
          mouseover: highlightFeature,
          mouseout: resetHighlight,
        });
      }

      // adds onEachFeature function to each country in countryList
      geoJson = L.geoJson(geoJsonCountryList.countryList, {
        style: style,
        onEachFeature: onEachFeature,
      }).addTo(mymap);

      //Creates div, where each name of the country will show
      let info = L.control();

      info.onAdd = function () {
        this._div = L.DomUtil.create("div", "info"); // create a div with a class "info"
        this.update();
        return this._div;
      };

      // Creates a box in the top corner where the name of the country will show when hovering over said country
      info.update = function (props) {
        this._div.innerHTML =
          "<h4>countries</h4>" +
          (props ? "<b>" + props.name + "</b><br>" + props.count + "<br />" : "hover over a country");
      };
      info.addTo(mymap);

      // generates a legend in bottomLeft
      let legend = L.control({ position: "bottomleft" });

      legend.onAdd = function () {
        let div = L.DomUtil.create("div", "info legend"),
          grades = [true, false], //equals the value of the 'isHighlight' parameter in the getColor function (line 51)
          labels = ["countries represented in the Life/community", "be the first one from your country"];


        // loop through our values in the getColor and generate a label with a colored circle for each interval
        // Last label removed by reducing grades.length with 1
        for (let i = 0; i < grades.length - 1; i++) {
          div.innerHTML +=
            '<i style="background:' + getColor(grades[i]) + '; border: 1px solid black; border-radius: 50%;"></i>' +
            labels[i] + '<br>';
        }

        return div;
      };

      legend.addTo(mymap);

      // Generate totals in the topleft
      let totals = L.control({ position: "topleft" });

      totals.onAdd = function () {
        let div = L.DomUtil.create("div", "info info_totals");

        div.innerHTML += '<p>total Life/participants: ' + jsonTotalsList.totalsList.totalRegistrations + '</p><br>' + '<p>registered countries: ' 
          + jsonTotalsList.totalsList.totalUniqueCountries + '</p>';

        return div;
      }

      //Changes zoom on different screen widths
      window.addEventListener('resize', function(){
          // get the width of the screen after the resize event
          var width = document.documentElement.clientWidth;
          
          if (width <= 800 && width >= 415) {
              mymap.setZoom(1);
          } else if (width < 415) {
              mymap.setZoom(0);
          } else {
              mymap.setZoom(1.5)
          }
      });
      
      this.getTotalPersonsAndCountries(totals, mymap);
    },
  },
  mounted() {
    // debugger;
    if (!this.isApplicationInitiated) {
      this.InitApplication();
    }
    this.loadPersonCountPerCountry();
  },
};
</script>

<style src='leaflet/dist/leaflet.css'></style>
<style>
  /* info = info-box top right that shows country names */
  .info {
    padding: 6px 8px;
    font: 16px/18px Arial, Helvetica, sans-serif;
    background: white;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    border-radius: 5px;
  }
  .info h4 {
      /* font-size: 20px; */
      margin: 0 0 5px;
      color: #33bdd7;
  }
/* info-box top left that shows totals */
  .info_totals p {
      font-size: 18px;
      font-weight: bold;
  }
/* Legend = box bottom left  */
  .legend {
      line-height: 22px;
      color: #555;
      text-align: left;
  }
  .legend i {
      width: 18px;
      height: 18px;
      float: left;
      margin-right: 8px;
      margin-top: 1px;
      opacity: 0.7;
  }
  .containter {
      display: flex;
  }
  path.leaflet-interactive {
    transition: 0.4s;
  }

  #mapid {
      height: 45rem;
      width: 100%;
      background: #FFFFFF;
      margin: 20px auto;
      border: solid 2px rgba(0, 0, 0, 0.534);
      border-radius: 5px;

  }

  @media only screen and (max-width: 922px) {
      #mapid {      
          height: 30rem;
          width: 65%;    
      }
  }

  @media only screen and (max-width: 800px) {
      #mapid {       
          height: 20rem;
          width: 65%;    
      }
  }

  @media only screen and (max-width: 367px) {
      #mapid {       
          height: 256px;
          width: 256px;
      }
  }
</style>